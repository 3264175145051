import { useState } from 'react'
import Input from '../general/input/input'
import './loginMain.scss'

import { unauthorizedRequest } from '../../network/requests'
import { loginUrl } from '../../network/urls'

import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

type errors = {
    email: string
    password: string
}

const LoginMain = () => {
    const [ t, i18n ] = useTranslation('login')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState<errors>({email: '', password: ''})

    const navigate = useNavigate()

    const checkErrors = () => {
        setErrors({email: '', password: ''})
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if(!emailRegex.test(email)){
            setErrors({...errors, email: 'incorrectEmail'})
            return true
        }
        if(password.length < 8){
            setErrors({...errors, password: 'incorrectPassword'})
            return true
        }
        if(password.length < 8 && !emailRegex.test(email)){
            setErrors({email: 'incorrectEmail' , password: 'incorrectPassword'})
            return true
        }

        return false
    }

    const loginSubmit = () => {
        if(!checkErrors()){

            unauthorizedRequest(loginUrl, 'POST', {
                email: email,
                password: password
            }).then((response) => {
                console.log(response)

                if(response === 401){
                    setErrors({email: 'incorrectPasOrEmail', password: 'incorrectPasOrEmail'})
                }else{
                    localStorage.setItem('accessToken', response.result.access_token)
					localStorage.setItem('refreshToken', response.result.refresh_token)
                    navigate('/form-list')
                }
            })
        }
    }

    return (
        <div className="login-main">
            <div className="container login-main-container">
                <h2 className="login-main-title">{t('title')}</h2>
                <h3 className="contact-main-subtitle">{t('email')}</h3>
                <Input 
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={false}
                    placeholder={t('email')}
                    error={errors.email}
                />
                <h3 className="contact-main-subtitle">{t('password')}</h3>
                <Input 
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={false}
                    placeholder={t('password')}
                    error={errors.password}
                />

                <div className="login-button" onClick={()=>loginSubmit()}>
                    <p>{t('title')}</p>
                </div>
            </div>
        </div>
    )
}

export default LoginMain