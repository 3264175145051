import './homeBanner.scss'

import Donate from '../../general/donate/donate'
import { useTranslation } from 'react-i18next'

const HomeBanner = () => {
    const [t, i18n] = useTranslation('home')

    return (
        <div className="home-banner">
            <div className="home-banner-content container">
                <h1 className="home-banner-title">{t('banner.title')}</h1>
                <h2 className="home-banner-subtitle">{t('banner.subtitle')}</h2>
                <div className="home-banner-donate">
                    <Donate bgColor='#FFE105' activeColor='#454545' color='#FFE105' fontSize='24px' />
                </div>
            </div>
        </div>
    )
}

export default HomeBanner