import './donate.scss'

import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

type donateProps = {
    bgColor: string
    color: string
    activeColor: string
    fontSize: string
}

const Donate: FC<donateProps> = ({ bgColor, color, activeColor, fontSize }) => {
    const [t, i18n] = useTranslation('global')

    const navigate = useNavigate()

    const onMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        const button = e.target as HTMLButtonElement

        if(button){
            button.style.background = bgColor
            button.style.color = activeColor
        }
    }

    const onMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        const button = e.target as HTMLButtonElement

        if(button){
            button.style.background = 'transparent'
            button.style.border = `1px solid ${bgColor}`
            button.style.color = color
        }
    }


    return (
        <button className='donate-button' style={{border: `1px solid ${bgColor}`, color: color, fontSize: fontSize}} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => navigate('/donate')}>
            {t('donate')}
        </button>
    )
}

export default Donate