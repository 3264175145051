import './paypal.scss'

import { useRef, useEffect, FC } from "react";
import { loadScript, PayPalNamespace, PayPalButtonsComponentOptions } from "@paypal/paypal-js";

const Paypal: FC = () => {
  const paypalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Load PayPal SDK with Venmo support 
    loadScript({
      clientId: "AcmmVreLbkwREtshI_YalJ8r-rCJfg0_7QzT3lcWZXMlG5If-UNgpwR0de3E1fuZOPrMdEGw8zbQM-YX", // Replace with your actual client ID
      currency: "USD", // Venmo supports only USD
      components: "buttons", // Load the buttons component
      intent: "capture", // Set intent globally to "capture"
    }).then((paypal: PayPalNamespace | null) => {
      if (!paypal || !paypal.Buttons) {
        console.error("PayPal Buttons failed to load.");
        return;
      }

      const buttonOptions: PayPalButtonsComponentOptions = {
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "pay", // Generic label for multiple payment methods
        },
        createOrder: (data, actions) => {
          if (!actions?.order) {
            console.error("Order actions are unavailable.");
            return Promise.reject(new Error("Order actions are unavailable."));
          }
          return actions.order.create({
            intent: "CAPTURE", // Ensure intent is included
            purchase_units: [
              {
                description: "Donation",
                amount: {
                  currency_code: "USD", // Venmo supports only USD
                  value: "0.01", // Placeholder amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          if (!actions?.order) {
            console.error("Order actions are unavailable.");
            return;
          }
          const order = await actions.order.capture();
          console.log("Payment successful:", order);
        },
        onError: (err) => {
          console.error("PayPal Checkout onError:", err);
        },
      };

      // Render both PayPal and Venmo buttons (conditionally)
      paypal.Buttons({
        ...buttonOptions,
        fundingSource: paypal.FUNDING?.PAYPAL, // PayPal button
      }).render(paypalRef.current as HTMLDivElement);

      paypal.Buttons({
        ...buttonOptions,
        fundingSource: paypal.FUNDING?.VENMO, // Venmo button
      }).render(paypalRef.current as HTMLDivElement);
    }).catch((error) => {
      console.error("Failed to load PayPal script:", error);
    });
  }, []);

  return <div className="paypal-container" ref={paypalRef}></div>;
};

export default Paypal;
