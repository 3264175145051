import { FC } from 'react'
import './homeStatsItem.scss'

type homeStatsItemProps = {
    title?: string,
    content: string
}

const HomeStatsItem: FC<homeStatsItemProps> = ({ title, content }) => {

    return (
        <div className="home-stats-item">
            { title ? <h2 className="home-stats-item-title">{title}</h2> : null }
            <p className="home-stats-item-content">{content}</p>
        </div>
    )
}

export default HomeStatsItem