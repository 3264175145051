import { FC } from 'react'
import './textarea.scss'
import { useTranslation } from 'react-i18next'

type textareaProps = {
    value: string
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    error?: string
}

const Textarea: FC<textareaProps> = ({value, placeholder, onChange, error}) => {
    const [ t, i18n ] = useTranslation('errors')


    return (
        <div className="textarea-wrapper">
            <textarea className={error? 'textarea-error textarea': 'textarea'}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            {
                error?
                    <div className="textarea-error-text">{t(`${error}`)}</div>
                    : null
            }
        </div>
    )
}

export default Textarea