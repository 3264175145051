import { useState } from 'react'
import Input from '../general/input/input'
import Loading from '../general/loading/loading'
import './applicationMain.scss'

import { useTranslation } from 'react-i18next'
import Textarea from '../general/textarea/textarea'
import Dropdown from '../general/dropdown/dropdown'
import { unauthorizedRequest } from '../../network/requests'
import { formsUrl } from '../../network/urls'

type errors = {
    name: string
    lastName: string
    age: string
    gender: string
    nationality: string
    countryOfResidence: string
    city: string
    currentGrade: string
    currentSchool: string
    email: string
    response: string
    
}

const ApplicationMain = () => {
    const [ t, i18n ] = useTranslation('application')

    const [ loading, setLoading ] = useState(false)

    const [errors, setErrors] = useState<errors>({name: '', lastName: '', age: '', gender: '', nationality: '', countryOfResidence: '', city: '', currentGrade: '', currentSchool: '', email: '', response: ''})
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [age, setAge] = useState('')
    const [genderOption, setGenderOption] = useState<{key: string, text: string} | undefined>(undefined)
    const [nationality, setNationality] = useState('')
    const [countryOfResidence, setCountryOfResidence] = useState('')
    const [city, setCity] = useState('')
    const [currentGrade, setCurrentGrade] = useState('')
    const [currentSchool, setCurrentSchool] = useState('')
    const [email, setEmail] = useState('')

    const [background, setBackground] = useState('')
    const [additional, setAdditional] = useState('')


    const submit = () => {
        let flag = true
        let newErrors = {...errors}
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (background.length < 250) {
            newErrors.response = 'writeResponse'
            flag = false
        } else {
            newErrors.response = ''
        }
    
        if (name.length === 0) {
            newErrors.name = 'writeName'
            flag = false
        } else {
            newErrors.name = ''
        }
    
        if (lastName.length === 0) {
            newErrors.lastName = 'writeLastName'
            flag = false
        } else {
            newErrors.lastName = ''
        }
    
        if (age.length === 0 || !(/^[0-9]+$/.test(age))) {
            newErrors.age = 'writeAge'
            flag = false
        } else {
            newErrors.age = ''
        }
    
        if (genderOption === undefined) {
            newErrors.gender = 'writeGender'
            flag = false
        } else {
            newErrors.gender = ''
        }
    
        if (nationality.length === 0) {
            newErrors.nationality = 'writeNationality'
            flag = false
        } else {
            newErrors.nationality = ''
        }
    
        if (countryOfResidence.length === 0) {
            newErrors.countryOfResidence = 'writeCountryOfResidence'
            flag = false
        } else {
            newErrors.countryOfResidence = ''
        }
    
        if (city.length === 0) {
            newErrors.city = 'writeCity'
            flag = false
        } else {
            newErrors.city = ''
        }
    
        if (currentGrade.length === 0 || !(/^[0-9]+$/.test(age))) {
            newErrors.currentGrade = 'writeCurrentGrade'
            flag = false
        } else {
            newErrors.currentGrade = ''
        }
    
        if (currentSchool.length === 0) {
            newErrors.currentSchool = 'writeCurrentSchool'
            flag = false
        } else {
            newErrors.currentSchool = ''
        }
    
        if (!emailRegex.test(email)) {
            newErrors.email = 'writeEmail'
            flag = false
        } else {
            newErrors.email = ''
        }
    
        if (flag) {
            setErrors({
                name: '', lastName: '', age: '', gender: '', nationality: '',
                countryOfResidence: '', city: '', currentGrade: '', currentSchool: '', email: '', response: ''
            });

            setLoading(true)

            unauthorizedRequest(formsUrl, 'POST', {
                first_name: name,
                last_name: lastName,
                age: age,
                gender: genderOption?.key,
                nationality: nationality,
                country: countryOfResidence,
                city: city,
                grade: currentGrade,
                school: currentSchool,
                email: email,
                background: background,
                additional: additional
            })
            .then((response) => {
                setLoading(false)
            })
            
        } else {
            setErrors(newErrors)
        }
    
    };
    


    return (
        <div className="application-main">
            <div className="application-main-container container">
                <h1 className="application-main-title">{t('prospectiveStudents')}</h1>
                <h1 className="application-main-title grey">{t('bigTitle1')}</h1>
                <p className="application-main-content-text">{t("text1")}</p>
                <div className="application-main-content-container">
                    <p className="application-main-content-text">• {t("text2")}</p>
                    <p className="application-main-content-text">• {t("text3")}</p>
                    <p className="application-main-content-text">• {t("text4")}</p>
                </div>
                <p className="application-main-content-text">{t("text5")}</p>

                <div className="application-main-inputs-container ">
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('name')}</h3>
                        <Input
                            type='text'
                            placeholder={t('name')}
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            error={errors?.name}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('lastName')}</h3>
                        <Input
                            type='text'
                            placeholder={t('lastName')}
                            value={lastName}
                            onChange={(e)=>setLastName(e.target.value)}
                            error={errors?.lastName}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('age')}</h3>
                        <Input
                            type='text'
                            placeholder={t('age')}
                            value={age}
                            onChange={(e)=>setAge(e.target.value)}
                            error={errors?.age}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('gender')}</h3>
                        <Dropdown
                            placeholder={t('gender')}
                            error={errors?.gender}
                            options={[
                                {
                                    key: 'male',
                                    text: t('male')
                                },
                                {
                                    key: 'female',
                                    text: t('female')
                                },
                                {
                                    key: 'other',
                                    text: t('other')
                                }
                            ]}
                            selectedOption={genderOption}
                            setSelectedOption={setGenderOption}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('nationality')}</h3>
                        <Input
                            type='text'
                            placeholder={t('nationality')}
                            value={nationality}
                            onChange={(e)=>setNationality(e.target.value)}
                            error={errors?.nationality}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('countryOfResidence')}</h3>
                        <Input
                            type='text'
                            placeholder={t('countryOfResidence')}
                            value={countryOfResidence}
                            onChange={(e)=>setCountryOfResidence(e.target.value)}
                            error={errors?.countryOfResidence}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('city')}</h3>
                        <Input
                            type='text'
                            placeholder={t('city')}
                            value={city}
                            onChange={(e)=>setCity(e.target.value)}
                            error={errors?.city}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('currentGrade')}</h3>
                        <Input
                            type='text'
                            placeholder={t('currentGrade')}
                            value={currentGrade}
                            onChange={(e)=>setCurrentGrade(e.target.value)}
                            error={errors?.currentGrade}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('currentSchool')}</h3>
                        <Input
                            type='text'
                            placeholder={t('currentSchool')}
                            value={currentSchool}
                            onChange={(e)=>setCurrentSchool(e.target.value)}
                            error={errors?.currentSchool}
                        />
                    </div>
                    <div className="application-main-input-container">
                        <h3 className="application-main-subtitle">{t('email')}</h3>
                        <Input
                            type='text'
                            placeholder={t('email')}
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            error={errors?.email}
                        />
                    </div>
                </div>
                <p className="application-main-content-text">{t("text6")}</p>
                <Textarea
                    value={background}
                    onChange={(e)=>setBackground(e.target.value)}
                    placeholder={t("response")}
                    error={errors.response}
                />
                <p className="application-main-content-text">{t("text7")}</p>
                <Textarea
                    value={additional}
                    onChange={(e)=>setAdditional(e.target.value)}
                    placeholder={t("response")}
                    error=''
                />

                <div className="application-button-container">

                    <div className="application-button" onClick={()=>submit()}>
                        <p>{t('finish')}</p>
                    </div>
                    {loading && <Loading/>}
                </div>
            </div>
        </div>
    )
}

export default ApplicationMain