import './homeStats.scss'

import { useTranslation, Trans } from 'react-i18next'

import HomeStatsItem from './homeStatsItem/homeStatsItem'

const HomeStats = () => {
    const [t, i18n] = useTranslation('home')


    return (
        <div className="home-stats">
            <h1 className="home-stats-title">{t('stats.title')}</h1>
            <div className="home-stats-container">
                <div className="home-stats-content">
                    <HomeStatsItem title={t('stats.stats.0.title')} content={t('stats.stats.0.content')}/>
                    <HomeStatsItem title={t('stats.stats.1.title')} content={t('stats.stats.1.content')}/>
                    <HomeStatsItem title={t('stats.stats.2.title')} content={t('stats.stats.2.content')}/>
                    <HomeStatsItem title={t('stats.stats.3.title')} content={t('stats.stats.3.content')}/>
                    <p dangerouslySetInnerHTML={{ __html: t('stats.stats.4.content') }}/>
                </div>
            </div>
        </div>
    )
}

export default HomeStats