import { FC } from 'react'
import './formListItem.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export type formListItemType = {
    id: number
    name: string
    lastName: string
    age: number
    gender: string
    nationality: string
    countryOfResidence: string
    city: string
    currentGrade: number
    currentSchool: string
    email: string
    date: Date
    background: string
    additional: string
}

const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',  // Full month name (e.g., January)
      day: 'numeric',  // Day of the month
      weekday: 'long', // Full weekday name (e.g., Monday)
    }).format(date);
  };
  

const FormListItem: FC<formListItemType> = ({
    id,
    name,
    lastName,
    age,
    gender,
    nationality,
    countryOfResidence,
    city,
    currentGrade,
    currentSchool,
    email,
    date
}) => {
    const [ t, i18n ] = useTranslation('formList')
    const navigate = useNavigate();

    return (
        <div className="form-list-item-wrapper" onClick={()=>navigate(`/form/${id}`)}>
            <div className="form-list-item-container">
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('name')}:</h3>
                    <div className="form-list-item-text">{name}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('lastName')}:</h3>
                    <div className="form-list-item-text">{lastName}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('age')}:</h3>
                    <div className="form-list-item-text">{age}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('gender')}:</h3>
                    <div className="form-list-item-text">{gender}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('nationality')}:</h3>
                    <div className="form-list-item-text">{nationality}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('countryOfResidence')}:</h3>
                    <div className="form-list-item-text">{countryOfResidence}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('city')}:</h3>
                    <div className="form-list-item-text">{city}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('currentGrade')}:</h3>
                    <div className="form-list-item-text">{currentGrade}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('currentSchool')}:</h3>
                    <div className="form-list-item-text">{currentSchool}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('email')}:</h3>
                    <div className="form-list-item-text">{email}</div>
                </div>
                <div className="form-list-item-info-container">
                    <h3 className="form-list-item-subtitle">{t('date')}:</h3>
                    <div className="form-list-item-text">{formatDate(date)}</div>
                </div>
            </div>
        </div>
    )
}

export default FormListItem