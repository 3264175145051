import './homeInfoItem.scss'

import { FC } from 'react'

type homeInfoItemProps = {
    title: string
    content: {
        text: string
        styles?: object
    }[]
}

const HomeInfoItem: FC<homeInfoItemProps> = ({ title, content }) => {
    return (
        <div className="home-info-item">
            <h2 className="home-info-item-title">{title}</h2>
            {
                content.map(item => {
                    return (
                        <p className="home-info-item-content" style={item.styles}>{item.text}</p>
                    )
                })
            }
        </div>
    )
}

export default HomeInfoItem