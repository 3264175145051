import { useEffect, useState } from 'react'
import Input from '../general/input/input'
import './formListMain.scss'

import { useTranslation } from 'react-i18next'
import FormListItem, { formListItemType } from './formListItem/formListItem'
import Pagination from '../general/pagination/pagination'
import { authorizedRequest } from '../../network/requests'
import { formsUrl } from '../../network/urls'
import Loading from '../general/loading/loading'

type formResponse = {
    additional: string
    age: number 
    background: string
    city: string
    country: string
    email: string
    first_name: string
    gender: string
    grade: number
    id: number
    last_name: string
    nationality: string
    school: string
    date: number
}

const FormListMain = () => {
    const [ t, i18n ] = useTranslation('formList')

    const [searchValue, setSearchValue] = useState('')
    const [formList, setFormList] = useState<formListItemType[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageNumber, setPageNumber] = useState<number>(1)

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        loadData(currentPage, searchValue)
        .then((data) => {
            setFormList([...formList, ...data])
        })

    }, [currentPage])

    const loadData = (page: number, request: string) => {
        setLoading(true)

        return authorizedRequest(formsUrl + `?page=${page}&needle=${request}`, 'GET')
        .then((response) => {
            console.log(response)

            const { result }: { result: {
                forms: formResponse[],
                total_pages: number
            } } = response

            setPageNumber(result.total_pages)
            setLoading(false)

            return [...result.forms.map((item) => {
                return {
                    id: item.id,
                    name: item.first_name,
                    lastName: item.last_name,
                    age: item.age,
                    gender: item.gender,
                    nationality: item.nationality,
                    countryOfResidence: item.country,
                    city: item.city,
                    currentGrade: item.grade,
                    currentSchool: item.school,
                    email: item.email,
                    date: new Date(item.date * 1000),
                    background: item.background,
                    additional: item.additional
                }
            })]


        })
    }

    useEffect(()=>{
        setCurrentPage(1)
        loadData(1, searchValue)
        .then((data) => {
            setFormList([...data])
        })
    }, [searchValue])

    return (
        <div className="form-list-main">
            <div className="form-list-main-container container">
                <div className="form-list-search-container">
                    <Input
                        type='text'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        disabled={false}
                        placeholder={t('Search')}
                    />
                </div>
                {loading && <Loading/>}
                <div className="form-list-content-container">
                    {
                        formList ?
                            formList.map((item: formListItemType)=>{
                                return(
                                    <FormListItem {...item}/>
                                )
                            })
                            : <h3 className="form-list-title">{t('noForms')}</h3>
                    }
                </div>

                <Pagination totalPages={pageNumber} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </div>
        </div>
    )
}

export default FormListMain
