import { useTranslation } from 'react-i18next';
import './dropdown.scss';
import { FC, useEffect, useState, useRef } from 'react';

type option = {
  text: string;
  key: string;
};

type dropdownProps = {
  placeholder: string;
  options: option[];
  selectedOption?: option;
  setSelectedOption: (value: option) => void;
  error?: string
};

const Dropdown: FC<dropdownProps> = ({
  placeholder,
  options,
  selectedOption,
  setSelectedOption,
  error
}) => {
    const [ t, i18n ] = useTranslation('errors')

    const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionSelect = (option: option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`dropdown-wrapper`} onClick={toggleDropdown} ref={dropdownRef}>
      <div className={`dropdown-title ${isOpen ? 'open' : ''} ${error ? 'dropdown-title-error' : ''}`}>
        <p>{selectedOption ? selectedOption.text : placeholder}</p>
        <div className="arrow" />
      </div>
      {
        error?
            <div className="dropdown-error-text">{t(`${error}`)}</div>
            : null
        }
      <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
        {options.map((option) => (
          <div
            key={option.key}
            className="dropdown-option-item"
            onClick={() => handleOptionSelect(option)}
          >
            <p>{option.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
