import './storyCard.scss'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type storyCardProps = {
    title: string
    description: string
    storyId: number
}

const StoryCard: FC<storyCardProps> = ({ title, description, storyId }) => {
    const [ t, i18n ] = useTranslation('global') 

    return (
        <div className="story-card">
            <div className="story-card-content-container">
                <div className="story-card-text-container">
                    <h2 className="story-card-title">{title}</h2>
                    <p className="story-card-description">{description}</p>
                </div>
                <Link to={`/stories/${storyId}`}>
                    <div className="view-more-button">
                        <p>{t('moreButton')}</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default StoryCard