import './homeInfo.scss'

import { useTranslation } from 'react-i18next'

import HomeInfoItem from './homeInfoItem/homeInfoItem'

const HomeInfo = () => {
    const [ t, i18n ] = useTranslation('home')

    return (
        <div className="home-info">
            <HomeInfoItem title={t('info.aboutUs.title')} content={[
                {
                    text: t('info.aboutUs.content.0')
                }, {
                    text: t('info.aboutUs.content.1'),
                    styles: {
                        width: '90%',
                        margin: '0 auto',
                        display: 'list-item',
                    }
                }, {
                    text: t('info.aboutUs.content.2'),
                    styles: {
                        width: '90%',
                        margin: '0 auto',
                        display: 'list-item',
                    }
                }, {
                    text: t('info.aboutUs.content.3'),
                    styles: {
                        width: '90%',
                        margin: '0 auto',
                        display: 'list-item',
                    }
                }, {
                    text: t('info.aboutUs.content.4')
                }, {
                    text: t('info.aboutUs.content.5'),
                    styles: {
                        fontStyle: 'italic',
                        textAlign: 'center'
                    }
                }, {
                    text: t('info.aboutUs.content.6')
                }
            ]}/>
            <HomeInfoItem title={t('info.ourMission.title')} content={[{text: t('info.ourMission.content')}]}/>
        </div>
    )
}

export default HomeInfo