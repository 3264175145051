import { Routes, Route } from "react-router-dom"
import Home from "./pages/home/home"
import Stories from "./pages/stories/stories"
import Story from "./pages/story/story"
import Contact from "./pages/contact/contact"
import Donate from "./pages/donate/donate"
import Authentication from "./pages/authentication/authentication"
import Login from "./pages/login/login"
import FormList from "./pages/formList/formList"
import Form from "./pages/form/form"
import Application from "./pages/application/application"

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/stories" element={<Stories/>}/>
            <Route path="stories/:storyId" element={<Story />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/log-in" element={<Login />} />
            <Route path="/form-list" element={<FormList />} />
            <Route path="/form/:formId" element={<Form />} />
            <Route path="/application" element={<Application />} />
        </Routes>
    )
}

export default Router