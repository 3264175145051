import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import FormMain from '../../components/form/formMain'

const Form = () => {
    return (
        <>
            <Header/>
            <FormMain/>
            <Footer/>
        </>
    )
}

export default Form