import { useEffect, useState } from 'react'
import './formMain.scss'

import { useTranslation } from 'react-i18next'
import { formListItemType } from '../formList/formListItem/formListItem'
import { useParams } from 'react-router-dom'
import { authorizedRequest } from '../../network/requests'
import { singleFormUrl } from '../../network/urls'

const FormMain = () => {
    const [ t, i18n ] = useTranslation('formList')

    const { formId } = useParams()

    const [ formData, setFormData ] = useState<formListItemType | undefined>(undefined) 

    useEffect(()=>{
        if(formId){
            authorizedRequest(singleFormUrl(parseInt(formId)), 'GET')
            .then((response) => {
                const { result } = response

                setFormData({
                    id: result.id,
                    name: result.first_name,
                    lastName: result.last_name,
                    age: result.age,
                    gender: result.gender,
                    nationality: result.nationality,
                    countryOfResidence: result.country,
                    city: result.city,
                    currentGrade: result.grade,
                    currentSchool: result.school,
                    email: result.email,
                    date: new Date(result.date * 1000),
                    background: result.background,
                    additional: result.additional
                })
            })
        }
    }, [formId])


    return (
        <div className="form-main">
            {
                formData &&
                    <div className="form-main-container container">
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('name')}</h3>
                            <p>{formData.name}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('lastName')}</h3>
                            <p>{formData.lastName}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('age')}</h3>
                            <p>{formData.age}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('gender')}</h3>
                            <p>{t(formData.gender)}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('nationality')}</h3>
                            <p>{formData.nationality}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('countryOfResidence')}</h3>
                            <p>{formData.countryOfResidence}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('city')}</h3>
                            <p>{formData.city}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('currentGrade')}</h3>
                            <p>{formData.currentGrade}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('currentSchool')}</h3>
                            <p>{formData.currentSchool}</p>
                        </div>
                        <div className="form-main-content-container">
                            <h3 className="form-main-subtitle">{t('email')}</h3>
                            <p>{formData.email}</p>
                        </div>
                        <div className="form-main-content-container full-width">
                            <h3 className="form-main-subtitle">{t('background')}</h3>
                            <p>{formData.background}</p>
                        </div>
                        <div className="form-main-content-container full-width">
                            <h3 className="form-main-subtitle">{t('additional')}</h3>
                            <p>{formData.additional}</p>
                        </div>
                    </div>
            }
        </div>
    )
}

export default FormMain

