import './storiesMain.scss'
import StoryCard from '../../general/storyCard/storyCard'

import storiesJSON from '../../../translations/en/stories.json'

import { useTranslation } from 'react-i18next'

const StoriesMain = () => {
    const [t, i18n] = useTranslation('stories')
    const tTitle = useTranslation('global').t


    return (
        <div className="stories-main">
            <h2 className="stories-title">{tTitle('storiesTitle')}</h2>
            <div className="stories-container container">
                {
                    storiesJSON.map(({storyId}, index) => {
                        return <StoryCard title={t(`${index}.title`)} description={t(`${index}.description`)} storyId={storyId} key={`story-card-${index}`}/>
                    })
                }
            </div>
        </div>
    )

}

export default StoriesMain