import './storyMain.scss'

import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Donate from '../../general/donate/donate'
import StorySlider from '../../general/storySlider/storySlider'

import storiesJson from '../../../translations/en/stories.json'


const StoryMain = () => {
    const { storyId } = useParams()
    const [ t, i18n ] = useTranslation('stories')

    const [ storyIndex, setStoryIndex ] = useState<number>(-1)

    useEffect(() => {
        setStoryIndex(storiesJson.findIndex((story) => story.storyId === parseInt(storyId || '-1') ))
    }, [])

    return (
        <div className="story-main">
            <div className="story-main-container container">
                <div className="story-main-content-container">
                    <div className="story-main-section">
                        <div className="story-main-section-content-container">
                            <h2 className="story-main-section-content-title">{t(`${storyIndex}.title`)}</h2>
                            <p className="story-main-section-content-text">{t(`${storyIndex}.description`)}</p>
                        </div>
                    </div>
                    <div className="story-main-section">
                        <div className="story-main-section-donate">
                            <Donate color='rgb(5, 118, 223)' bgColor='rgb(5, 118, 223)' fontSize='16px' activeColor='#fff' />
                        </div>
                    </div>
                </div>
                <StorySlider/>
            </div>
        </div>
    )
}

export default StoryMain