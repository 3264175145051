import './contact.scss'

import Header from '../../components/general/header/header'
import ContactMain from '../../components/contact/contactMain'
import Footer from '../../components/general/footer/footer'

const Contact = () => {

    return (
        <>
            <Header/>
            <ContactMain/>
            <Footer/>
        </>
    )
}

export default Contact