import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import ApplicationMain from '../../components/application/applicationMain'

const Application = () => {

    return (
        <>
            <Header/>
            <ApplicationMain/>
            <Footer/>
        </>
    )
}

export default Application