import './contactMain.scss'

import { useTranslation } from 'react-i18next'

import contactJson from '../../translations/en/contact.json'
import { Link } from 'react-router-dom'

const ContactMain = () => {
    const [ t, i18n ] = useTranslation('contact')

    return (
        <div className="contact-main">
            <div className="contact-main-container container">
                <h2 className="contact-main-title">{t('title')}</h2>
                <h3 className="contact-main-subtitle">{t('subtitle')}</h3>
                {
                    contactJson.content.map(({text, link}, index) => {
                        return (
                            <div className="contact-main-content-container">
                                <h2 className="contact-main-content-title">{t(`content.${index}.title`)}</h2>
                                {
                                    text.map((text, textIndex) => {
                                        return <p className="contact-main-content-text">{t(`content.${index}.text.${textIndex}`)}</p>
                                    })
                                }
                                {
                                    link.map(({href}, linkIndex) => {
                                        return <Link to={href}>{t(`content.${index}.link.${linkIndex}.text`)}</Link>
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ContactMain