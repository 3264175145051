import './authentication.scss'

import { useTranslation } from 'react-i18next'

import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import AuthenticationMain from '../../components/authentication/authenticationMain'

const Authentication = () => {
 
    const [t, i18n] = useTranslation('global')

    return (
        <>
            <Header/>
            <AuthenticationMain/>
            <Footer/>
        </>
    )
}

export default Authentication