import './donate.scss'

import Header from '../../components/general/header/header'
import DonateMain from '../../components/donateMain/donateMain'
import Footer from '../../components/general/footer/footer'

const Donate = () => {

    return (
        <>
            <Header/>
            <DonateMain/>
            <Footer/>
        </>
    )
}

export default Donate