import './storySlider.scss'

import StoryCard from '../storyCard/storyCard'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'

import storiesJSON from '../../../translations/en/stories.json'

const StorySlider = () => {
    const [ t, i18n ] = useTranslation('home')
    const [ tStories, i18nStories ] = useTranslation('stories')
    const [ sliderPage, setSliderPage] = useState(0)
    const sliderContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {

        const sliderContainer = sliderContainerRef.current
        const storyCard = document.querySelector('.story-card')

        if(sliderContainer && storyCard){
            sliderContainer.style.transform = `translateX(-${(storyCard.clientWidth + 25) * sliderPage}px)`
        }

    }, [sliderPage])

    const scrollLeft = () => {
        if(sliderPage >= 0){
            setSliderPage(sliderPage - 1)
        }
    }

    const scrollRight = () => {
        const sliderContainer = document.querySelector('.stories-slider-container')
        const storyCard = document.querySelector('.story-card')

        let cardsPerContainer = 4

        if(sliderContainer && storyCard){
            cardsPerContainer = Math.floor(sliderContainer.clientWidth/storyCard.clientWidth)
        }

        if(sliderPage < storiesJSON.length - cardsPerContainer){
            setSliderPage(sliderPage + 1)
        }
    }

    return (
        <div className="stories">
            <div className="stories-header">
                <h2 className="stories-title">{t('stories.title')}</h2>
                <div className="stories-slider-buttons-container">
                    <div className="stories-slider-button" onClick={scrollLeft}>
                        <FaChevronLeft/>
                    </div>
                    <div className="stories-slider-button" onClick={scrollRight}>
                        <FaChevronRight/>
                    </div>
                </div>
            </div>
            <div className="stories-slider-wrapper">
                <div className="stories-slider-container" ref={sliderContainerRef}>
                    {
                        storiesJSON.map(({storyId}, index) => {
                            return <StoryCard title={tStories(`${index}.title`)} description={tStories(`${index}.description`)} storyId={storyId} key={`story-card-${index}`}/>
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default StorySlider