import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import FormListMain from '../../components/formList/formListMain'

const FormList = () => {

    return (
        <>
            <Header/>
            <FormListMain/>
            <Footer/>
        </>
    )
}

export default FormList