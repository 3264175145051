import './loading.scss';
import { FC } from 'react';

const Loading: FC = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Loading;
