import './authenticationMain.scss'
import { useTranslation } from 'react-i18next'

const AuthenticationMain = () => {
 
    const [t, i18n] = useTranslation('global')

    return (
        <div className="authentication-wrapper">
            <div className="authentication-content container">
                <div className="authentication-title">{t("authentication.authentication")}</div>
                <p>{t("authentication.footerText1")}</p>
                <p>{t("authentication.footerText2")}</p>
                <p>
                    {t("authentication.footerText3")} <a href='https://www.ic3.gov' target="_blank" rel="noopener noreferrer">ic3.gov</a> {t("authentication.and")} <a href='https://reportfraud.ftc.gov' target="_blank" rel="noopener noreferrer">reportfraud.ftc.gov</a>.
                </p>
            </div>
        </div>
    )
}

export default AuthenticationMain