import './stories.scss'

import Header from '../../components/general/header/header'
import Footer from '../../components/general/footer/footer'
import StoriesMain from '../../components/stories/storiesMain/storiesMain'

const Stories = () => {

    return (
        <>
            <Header/>
            <StoriesMain/>
            <Footer/>
        </>
    )
}

export default Stories